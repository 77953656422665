<template>
  <div class="w-full">
    <div
      class="border-b-2 rounded-[20px] border-accent px-6 py-4 bg-[#FF9923]/10 flex"
    >
      <div class="sm:w-[280px]">
        <div class="font-serif text-[24px] leading-none">
          <div v-if="authStore.totalStars" class="text-[#FF8A00]">
            {{ authStore.totalStars }} estrelas
          </div>
          <div v-else class="text-[#FF8A00]">Ganhe sua primeira estrela</div>
        </div>
        <p class="text-[16px] mt-2">
          Cada estrela representa uma questão que você acertou.
        </p>
      </div>
      <div class="ml-4 flex items-center">
        <img
          src="/images/icons/star.png"
          class="min-w-[60px] h-[60px] opacity-80"
        />
      </div>
    </div>

    <div class="px-6 pt-4">
      <div class="grid grid-cols-2 gap-4 mb-4">
        <div
          v-for="sub in authStore.subjectsStars"
          :key="sub.id"
          class="border-2 rounded-[20px] border-accent text-center p-2"
        >
          <h5 class="font-bold text-[18px]">
            {{ sub.title }}
          </h5>
          <div class="font-serif text-[28px] text-[#FF8A00] leading-none mt-2">
            {{ sub.stars }}
          </div>
          <p class="text-[#838383]">
            Estrela<span v-if="sub.stars !== 1">s</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const authStore = useAuthStore();
</script>
