import { useQuery } from '@tanstack/vue-query'

export const getActiveTestsQuery = () => ({
  queryKey: ['active-tests'],
  queryFn: async () => {
    const api = useApi()
    const response = await api.test.activeTests.$get();

    const data = await response.json()

    return data
  }
})

export const useGetActiveTestsQuery = () => {
  return useQuery(getActiveTestsQuery())
}
