const ROUTE_EXCLUDES = ["/entrar", "/sair", "/inicio", "/comecar", "/termos-de-uso", "/privacidade"];

export default async function useLoggedInChecker() {
  const isApp = useIsApp();
  const token = useToken();
  const route = useRoute();

  if (!!token.value && !ROUTE_EXCLUDES.includes(route.path)) {
    await navigateTo("/inicio", { replace: true });
  }
}
