<template>
  <div class="landing-container">
    <UiMarquee :fade="true" :pauseOnHover="true">
      <div class="border-2 flex gap-2 border-accent rounded-3xl p-4 w-[350px] max-w-full bg-white"
        v-for="testimonial in testimonials" :key="testimonial.name">
        <div class="font-serif text-[48px] text-gray-500 relative top-[-10px]">
          “
        </div>
        <div class="grid gap-2">
          <div class="font-medium">{{ testimonial.text }}</div>
          <div class="font-medium text-gray-500 inline-flex items-center gap-2 -ml-8">
            <img :src="testimonial.photoUrl" class="size-8 rounded-full" />
            {{ testimonial.name }}
          </div>
        </div>
      </div>
    </UiMarquee>
  </div>
</template>

<script lang="ts" setup>
import { UiMarquee } from '../ui';

const testimonials = [
  {
    name: "Ana Laura",
    photoUrl: "/images/landing/socialproof/1.jpg",
    text: "Fui de 10 para mais de 28 acertos em matemática só fazendo questões."
  },
  {
    name: "Mariah Duarte", 
    photoUrl: "/images/landing/socialproof/4.jpg",
    text: "Fiquei muito tranquila na prova porque já sabia o que ia cair."
  },
  {
    name: "Isabela Santos",
    photoUrl: "/images/landing/socialproof/3.jpg", 
    text: "Saber minha nota do TRI antes do ENEM me deu mais segurança no dia da prova."
  },
  {
    name: "Ricardo Soares",
    photoUrl: "/images/landing/socialproof/2.jpg", 
    text: "Fiz 151 acertos estudando pelas resoluções, nem acreditei."
  }
];
</script>
