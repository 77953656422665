import { queryOptions, useQuery } from "@tanstack/vue-query";

export const useGetAuthQuery = () => {
  return useQuery({
    ...getAuthQuery(),
  });
};

export const getAuthQuery = () => {
  return queryOptions({
    queryKey: ["auth"],
    queryFn: async () => {
      const response = await useApi().auth.$get();

      if (!response.ok) {
        throw new Error("Failed to fetch auth");
      }

      const data = await response.json();
      return data;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
