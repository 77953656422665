import { Purchases } from "@revenuecat/purchases-capacitor";
import { useMutation } from "@tanstack/vue-query";
import { toast } from "~/components/toast";

export const useRestorePurchase = () => {
  const router = useRouter()

  return useMutation({
    mutationFn: async () => {
      const api = useApi()
      const result = await Purchases.restorePurchases();

      const res = await api.payment.activateAppPurchase.$post()

      if (!res.ok) {
        throw new Error('')
      }

      await router.push('/sucesso')

      return res
    },
    onError: () => {
      console.log("restore error");
      toast.error('Não foi possível restaurar a compra. Se isso é um erro, entre em contato com o suporte.')
    }
  });
};
