<template>
  <UiDrawer v-model="modalOpened">
    <div v-if="plans" class="p-2 h-[calc(90dvh)] overflow-auto pb-10" :class="{
      '!h-auto !overflow-hidden !pb-6': isApp,
    }">
      <div class="max-w-[600px] mx-auto">
        <div class="leading-none mb-1 font-serif text-[24px] text-center flex justify-center items-center">
          <span class="whitespace-nowrap">respostaCerta</span>
          <span class="text-primary ml-2">PREMIUM</span>
          <img draggable="false" src="/images/icons/star.png" class="min-w-[28px] h-[28px] opacity-80 ml-2" />
        </div>
        <p class="text-[18px] text-center">
          Libere todos os recursos da plataforma
        </p>

        <Benefits />

        <div class="mt-4 font-medium">Escolha um plano:</div>

        <div v-if="!isApp" class="mt-2 grid gap-2" :class="{
          'mt-4': isApp
        }">
          <div v-for="p in plans" class="relative border-2 cursor-pointer rounded-[14px] p-4 flex items-center"
            @click="plan = p.id" :class="{
              'border-primary text-primary': plan === p.id,
              'border-accent/60': plan !== p.id,
            }">
            <div class="font-bold text-[20px]">✓</div>
            <div class="font-serif text-[24px] ml-2">{{ p.name }}</div>
            <div class="ml-auto text-right text-black">
              <div class="font-bold tracking-wide">{{ p.byMonthFormated }} / mês</div>
              <div class="text-[14px] text-accent">Total de {{ p.totalFormated }}</div>
            </div>

            <div v-if="p.bestPrice"
              class="absolute right-4 text-white px-4 text-[14px] top-0 -translate-y-1/2 bg-primary rounded-[4px]">
              Melhor preço
            </div>
          </div>
        </div>

        <div v-else class="mt-2 grid gap-2">
          <div class="w-full flex justify-center items-center mt-4" v-if="isLoading">
            <UiLoading />
          </div>
          <div v-else class="grid gap-2">
            <div v-for="(offering, i) in offerings"
              class="relative border-2 cursor-pointer rounded-[14px] p-4 flex items-center"
              @click="currentOfferingIndex = i" :class="{
                'border-primary text-primary': currentOfferingIndex === i,
                'border-accent/60': currentOfferingIndex !== i,
              }">
              <div class="font-bold text-[20px]" :class="{
                'opacity-0': currentOfferingIndex !== i,
              }">✓</div>
              <div class="font-serif text-[24px] ml-2">{{ offering.product.title?.replace('PREMIUM', '') }}</div>
              <div class="ml-auto text-right text-black">
                <div class="font-bold tracking-wide text-2xl">{{ offering.product.priceString }}</div>
                <div class="text-sm text-accent whitespace-nowrap">
                  {{ offering.product.priceDescription }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <UiButton :loading="isPurchasing" @click="handlePurchase" size="lg" class="mt-6">
          <div class="flex flex-col">
            <span>Continuar</span>
          </div>
        </UiButton>

        <UiButton v-if="isApp" :loading="isRestoring" variant="flat" bg-color="white" @click="handleRestore" size="sm"
          class="mt-2">
          Restaurar compra
        </UiButton>

        <div v-if="isApp" class="mt-4 text-center text-sm text-gray-600 whitespace-pre-wrap">
          Essa é uma assinatura renovável automaticamente no período escolhido. Ao continuar, você concorda com os
          <a :href="termsLink" class="underline" target="_blank">Termos de Uso</a> e
          <a :href="privacyLink" class="underline" target="_blank">Política de Privacidade</a>.
        </div>
      </div>
    </div>
  </UiDrawer>
</template>

<script lang="ts" setup>
import { usePlans } from "~/domains/payment/composables/usePlans";
import { UiDrawer, UiButton, UiLoading } from "../ui";
import Benefits from "./benefits.vue";
import { sendPlausibleEvent } from "~/libs/plausible";
import { useListAppOfferings } from "~/domains/payment/queries/useListAppOfferings";
import { usePurchasePackage } from "~/domains/payment/mutations/usePurchasePackage";
import { useRestorePurchase } from "~/domains/payment/mutations/useRestorePurchase";

const { plans, plan, modalOpened, goToPayment } = usePlans();
const { data: offerings, isLoading } = useListAppOfferings();
const { termsLink, privacyLink } = useDevice()

const currentOfferingIndex = ref(0)
const currentOffering = computed(() => {
  const offering = offerings.value?.[currentOfferingIndex.value]

  if (!offering) return null

  return offering
})

const isApp = useIsApp()

const { mutate: purchasePackage, isPending: isPurchasing } = usePurchasePackage()
const { mutate: restorePurchase, isPending: isRestoring } = useRestorePurchase()

const handleRestore = () => {
  restorePurchase()
}

const handlePurchase = () => {
  if (isApp) {
    if (!currentOffering.value) {
      console.log("no offering selected")
      return
    }

    purchasePackage(toRaw(currentOffering.value))
  } else {
    goToPayment()
  }
}

watch(modalOpened, (value) => {
  if (value) {
    sendPlausibleEvent("Modal Pagamento - Abertura")
  }
})
</script>
