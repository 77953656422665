<template>
  <!-- Navbar -->
  <div class="w-full h-[60px] fixed top-0 flex items-center z-50 transition-all bg-background" :class="{
    'bg-white/80 backdrop-blur-lg shadow border-b': !arrivedState.top
  }">
    <div class="landing-container flex items-center gap-2">
      <NuxtLink draggable="false" to="/" class="flex items-center gap-2">
        <img src="~/assets/images/logo.png" class="relative h-[24px] w-[24px] md:h-[32px] md:w-[32px]" />
        <span class="font-serif text-[20px] text-primary">respostaCerta</span>
      </NuxtLink>

      <div v-if="!isOnTermsOrPrivacy" class="flex ml-auto items-center gap-4 transition-all">
        <NuxtLink draggable="false" to="/entrar" class="ml-auto">
          <UiButton size="xs" :variant="arrivedState.top ? 'default' : 'flat'" bg-color="white" class="mt-[12px]">
            Entrar
          </UiButton>
        </NuxtLink>
        <NuxtLink v-motion :initial="{
          opacity: 0,
          x: 40,
        }" :enter="{
            opacity: 1,
            x: 0,
          }" :leave="{
            opacity: 0,
            x: 40,
          }" v-if="!arrivedState.top" draggable="false" to="/cadastrar" class="hidden md:block ml-auto">
          <UiButton @click="sendPlausibleEvent('Landing - CTA Cadastro (Navbar)')" size="xs" class="mt-[12px]">
            Cadastre-se
          </UiButton>
        </NuxtLink>
      </div>
    </div>
  </div>

</template>

<script lang="ts" setup>
import { useScroll } from '@vueuse/core'
import { UiButton } from "~/components/ui";
import { sendPlausibleEvent } from "~/libs/plausible";
const { arrivedState } = useScroll(window)

const route = useRoute()

const isOnTermsOrPrivacy = computed(() => {
  return route.path.includes('/termos-de-uso') || route.path.includes('/privacidade')
})
</script>
