<template>
  <DrawerRoot
    :open="model"
    @update:open="model = $event"
    :should-scale-background="false"
    :close-threshold="0.1"
  >
    <DrawerPortal>
      <DrawerOverlay class="fixed inset-0 z-50 bg-white/80" />
      <DrawerContent
        class="fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px]  border-2 border-accent bg-white container"
        :class="props.contentClass"
      >
        <div class="mx-auto my-4 h-2 w-[100px] rounded-full bg-accent/30" />
        <div class="overflow-auto">
          <slot />
        </div>
      </DrawerContent>
    </DrawerPortal>
  </DrawerRoot>
</template>

<script setup lang="ts">
import {
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  DrawerRoot,
} from "vaul-vue";

const model = defineModel<boolean>();

interface DrawerProps {
  contentClass?: string;
}

const props = defineProps<DrawerProps>();
</script>
