<template>
  <div @mouseover="() => openedMenu = props.id" @mouseleave="() => openedMenu = null" ref="navItem"
    class="sm:relative hover:bg-gray-500/10 cursor-pointer rounded-[10px] h-[40px] inline-flex items-center px-4 gap-[10px] font-serif text-[18px]"
    :class="{
      'bg-gray-500/10': isHovered
    }">
    <img :src="icon" class="size-[24px]" :class="{
      'size-[32px]': user
    }" />
    <span v-if="value !== undefined">{{ value }}</span>

    <div v-if="isHovered"
      class="shadow-xl absolute left-2 sm:left-auto bottom-0 translate-y-[100%] right-0 font-sans max-w-[calc(100vw-16px)] cursor-default">
      <div class="mt-2 border-2 bg-white border-accent rounded-[20px] overflow-hidden">
        <slot @close="isHovered = false" />
      </div>
    </div>

    <!-- overlay -->
    <Teleport to="body">
      <Transition name="fade">
        <div v-if="isHovered" class="fixed inset-0 z-20 w-screen h-screen bg-white/60" @click="openedMenu = null"></div>
      </Transition>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
interface NavItemProps {
  icon: string;
  value: string | number;
  id: string;
  user?: boolean;
}
const { openedMenu } = useNavbar()
const props = defineProps<NavItemProps>();

const navItem = ref();

const isHovered = computed(() => {
  return openedMenu.value === props.id
})
</script>
