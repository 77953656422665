import { Device, type DeviceInfo } from '@capacitor/device';
import { Capacitor } from "@capacitor/core";
import { useScreenOrientation } from '@vueuse/core'

const device = ref<DeviceInfo | null>(null)

export const useDevice = () => {
  const isApp = useIsApp();
  const { orientation } = useScreenOrientation()

  const init = async () => {
    device.value = await Device.getInfo();
  }

  const termsLink = computed(() => {
    if (isApp && info.value.isIos) {
      return 'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'
    }
  
    return 'https://respostacerta.com/termos-de-uso'
  })

  const isIosOnWeb = computed(() => {
    return !isApp && info.value.device.value?.operatingSystem?.toLowerCase() === 'ios'
  })

  const privacyLink = computed(() => {
    return 'https://respostacerta.com/privacidade'
  })

  const info = computed(() => {
    return {
      device,
      isApp,
      platform: Capacitor.getPlatform(),
      isAndroid: Capacitor.getPlatform() === 'android',
      isIos: Capacitor.getPlatform() === 'ios',
      isWeb: Capacitor.getPlatform() === 'web',
      isPortrait: orientation.value?.includes('portrait'),
      isLandscape: orientation.value?.includes('landscape'),
      isIphone: device.value?.model?.toLocaleLowerCase().includes('iphone'),
      isIpad: device.value?.model?.toLocaleLowerCase().includes('ipad')
    }
  })

  return {
    init,
    isIosOnWeb,
    termsLink,
    privacyLink,
    device,
    info
  }
};

