<template>
  <div
    v-motion
    :initial="{
      opacity: 0,
      y: 20,
      scale: 0.7,
    }"
    :enter="{
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 30,
        mass: 0.5,
      },
    }"
    class="max-w-[calc(100vw-20px)] px-4 py-2 border border-black font-medium shadow-lg bg-white rounded-lg w-auto inline-flex items-center self-start mx-auto cursor-pointer text-lg"
    @click="$emit('close')"
  >
    <div
      v-if="meta"
      :class="`w-[24px] h-[24px] rounded-full flex items-center justify-center text-white mr-3 ${meta.class}`"
    >
      <v-icon
        v-motion
        :initial="{
          scale: 0,
        }"
        :enter="{
          opacity: 1,
          scale: 1,
          transition: {
            delay: 120,
            type: 'spring',
            stiffness: 260,
            damping: 30,
            mass: 2,
          },
        }"
        :name="meta.icon"
        class="min-w-[32px]"
      />
    </div>
    {{ options.title }}
  </div>
</template>

<script lang="ts" setup>
import { useMotions } from "@vueuse/motion";
import type { ToastOptions } from ".";

const motions = useMotions();

interface ToastItemProps {
  options: ToastOptions;
}

const props = defineProps<ToastItemProps>();

const meta = computed(() => {
  switch (props.options.type) {
    case "success":
      return {
        icon: "hi-solid-check",
        class: "bg-green-400",
      };
    case "error":
      return {
        icon: "hi-x",
        class: "bg-red-400",
      };
    default:
      return null;
  }
});

const emit = defineEmits<{
  (e: "close"): void;
}>();

onMounted(() => {
  setTimeout(() => {
    emit("close");
  }, 3000);
});
</script>
