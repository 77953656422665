import { OhVueIcon, addIcons } from "oh-vue-icons";
import {
  HiSolidCheck,
  HiX,
  HiCheck,
  PrSpinner,
  HiChevronDown,
  BiInfoSquare,
  MdClose,
  BiInfoSquareFill,
  HiSolidMinusSm,
  BiPlus,
  BiArrowLeftCircle,
  BiEyeSlash,
  BiEye,
  BiArrowRightShort
} from "oh-vue-icons/icons";

export default defineNuxtPlugin((nuxt) => {
  addIcons(
    HiSolidCheck,
    HiX,
    HiCheck,
    PrSpinner,
    HiChevronDown,
    BiInfoSquare,
    MdClose,
    BiInfoSquareFill,
    HiSolidMinusSm,
    BiPlus,
    BiArrowLeftCircle,
    BiEyeSlash,
    BiEye,
    BiArrowRightShort
  );

  nuxt.vueApp.component("v-icon", OhVueIcon);
});
