import { Capacitor } from "@capacitor/core";
import {LOG_LEVEL, Purchases} from "@revenuecat/purchases-capacitor";

export const usePurchases = () => {
  const platform = Capacitor.getPlatform()
  const configure = async (appUserID: string) => {
    if (platform === 'web')  {
      console.warn('Purchases are not supported on web')
      return;
    }
    await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
    await Purchases.configure({
      apiKey: "appl_ajusfLFDpIcRznCqdMlzvMSUAXS",
      appUserID: appUserID
    });
  };

  return {
    configure
  }
}