import { sendPlausibleEvent } from "~/libs/plausible";
import { formatCurrency } from "../lib/formatCurrency";
import { useGetPlansQuery } from "../queries/useGetPlansQuery";
import { useRouter } from "vue-router";

const plan = ref<"annual" | "quarterly">("annual");
const modalOpened = ref(false);

export const usePlans = () => {
  const router = useRouter();
  const { data } = useGetPlansQuery();

  const plans = computed(() => {
    if (!data.value) return [];

    return data.value?.map((p) => ({
      ...p,
      selected: p.id === plan.value,
      totalFormated: formatCurrency(p.total),
      byMonthFormated: formatCurrency(p.total / p.months),
    }));
  });

  const goToPayment = () => {
    if (!plan.value) return;

    if (plan.value === 'annual') {
      sendPlausibleEvent("Modal Pagamento - Escolheu plano (Anual)")
    } else {
      sendPlausibleEvent("Modal Pagamento - Escolheu plano (Trimestral)")
    }

    router.push(`/assinar?plan=${plan.value}`);
    modalOpened.value = false;
  };

  const openPlansModal = () => {
    modalOpened.value = true;
  };

  const from = computed(() => {
    if (!plan.value) return 0;

    return plans.value[0].byMonthFormated
  });

  return {
    plans,
    plan,
    modalOpened,
    from,
    openPlansModal,
    goToPayment,
    benefits
  };
};

const benefits = [
  "Aprenda com resoluções comentadas",
  "Veja sua nota detalhada por competência",
  "Treine com questões por competência",
  "Simulados com relatório completo",
];