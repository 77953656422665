import { usePlans } from "~/domains/payment/composables/usePlans"

type NavAction = () => void

const currentAction = ref<NavAction | null>(null)
const menuOpen = ref(false)
const backIconVisible = ref(false)
const hideButtons = ref(false)
const openedMenu = ref<string | null>(null)


export const useNavbar = () => {
  const router = useRouter()
  const route = useRoute()
  const { openPlansModal } = usePlans();
  const isApp = useIsApp()
  const { isIosOnWeb } = useDevice()

  watch(() => route.path, () => {
    currentAction.value = null
    backIconVisible.value = false
  })

  const authStore = useAuthStore()

  const isInHome = computed(() => route.fullPath === '/inicio')

  const goBack = () => {
    if (currentAction.value) {
      currentAction.value()
    } else {
      router.push('/inicio')
    }
  }

  const setParams = (params: {
    action: NavAction | null
    hideButtons?: boolean
    hideBackIcon?: boolean
  }) => {
    setTimeout(() => {
      nextTick(() => {
        currentAction.value = params.action
        backIconVisible.value = params.hideBackIcon ?? true
        hideButtons.value = params.hideButtons ?? false
      })
    }, 250)
  }

  const menuItems = computed(() => {
    const items: NavBarItem[] = [
        {
          title: 'Minha conta',
          emoji: '👤',
          to: '/minha-conta',
          subItems: [
            {
              title: "Meus dados",
              emoji: "👤", 
              to: "/minha-conta"
            },
            {
              title: "Língua estrangeira",
              emoji: "🌍",
              to: "/minha-conta"
            },
            {
              title: "Assinatura",
              emoji: "⭐",
              to: "/minha-conta/assinatura"
            },
            {
              title: 'Senha',
              emoji: '🔐',
              to: '/minha-conta/senha'
            },
            {
              title: 'Excluir conta',
              emoji: '🚨',
              to: '/minha-conta/deletar'
            },
          ]
        }
      ]

    if (!authStore.isPremium) {
      items.push({
        title: 'Seja Premium',
        emoji: '⭐',
        action: () => {
          openPlansModal();
          console.log('openPlansModal')
        },
      })
    }

    if (isIosOnWeb.value) {
      items.push({
        title: 'Instalar App',
        emoji: '📱',
        href: 'https://apps.apple.com/us/app/respostacerta-quest%C3%B5es-enem/id6740682044',
      })
    }

    return [
      ...items,
      {
        title: 'Ajuda',
        emoji: '❓',
        to: '/ajuda',
      },
      {
        title: 'Sair',
        emoji: '👋',
        to: '/sair',
      },
    ]
  })


  return {
    goBack,
    setParams,
    hideButtons,
    menuOpen,
    menuItems,
    isInHome,
    openedMenu
  }
}

export type NavBarItem = {
  title: string
  emoji: string
  to?: string
  href?: string
  action?: () => void,
  subItems?: {
    title: string
    emoji: string
    to?: string
    href?: string
    action?: () => void
  }[]
}