export function useCountdown(goalDate: MaybeRefOrGetter<Date>) {
  const isRunning = ref(true);
  const secondsLeft = ref(0);

  const formatedTimeLeft = computed(() => {
    const timeLeft = secondsLeft.value;
    const days = Math.floor(timeLeft / (60 * 60 * 24));
    const hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((timeLeft % (60 * 60)) / 60);
    const seconds = Math.floor(timeLeft % 60);

    return { days, hours, minutes, seconds, totalTimeLeftInSeconds: timeLeft };
  });

  const start = () => {
    const now = new Date();
    const timeLeft = toValue(goalDate).getTime() - now.getTime();
    secondsLeft.value = Math.floor(timeLeft / 1000);

    if (secondsLeft.value <= 0) {
      isRunning.value = false;
    }

    if (isRunning.value) {
      setTimeout(start, 1000);
    }
  };

  onMounted(() => {
    start();
  });

  onUnmounted(() => {
    isRunning.value = false;
  });

  return { start, finishTimeCountdown: formatedTimeLeft };
}