<template>
  <div class="pb-[50px] bg-white" :class="{ '!pb-[100px]': shouldHaveExtraPadding }">
    <nav class="h-[62px] shadow-lg fixed top-0 w-screen z-50 bg-white"
      :class="{ '!h-[110px] pt-[48px]': shouldHaveExtraPadding }">
      <div class="container flex items-center h-full">
        <div v-motion :initial="{
          opacity: 0,
          x: 4,
        }" :enter="{
          opacity: 1,
          x: 0,
        }" class="flex items-center" v-if="!isInHome">
          <div v-if="!hideBackIcon" draggable="false" @click.stop.prevent="goBack()">
            <img draggable="false" class="cursor-pointer fill-red-400" src="/images/icons/back-nav.svg" />
          </div>
        </div>
        <div v-else class="flex items-center">
          <img v-motion :enter="{
            opacity: 1,
            scale: 1,
          }" :initial="{
            opacity: 0,
            scale: 0.4,
          }" src="~/assets/images/logo.png" class="relative z-10 h-8 w-8" />
          <span v-motion :enter="{
            opacity: 1,
            x: 0,
          }" :initial="{
            opacity: 0,
            x: -10,
          }" class="relative z-0 font-serif ml-2 text-accent text-[22px] hidden md:block">respostaCerta</span>
        </div>

        <div v-if="!hideButtons" class="flex ml-auto">
          <UiNavitem id="streak" icon="/images/icons/fire.png" :value="authStore.studentStreak.streak">
            <StreakPanel />
          </UiNavitem>
          <UiNavitem id="stars" icon="/images/icons/star.png" :value="authStore.totalStars">
            <StarsPanel />
          </UiNavitem>
          <UiNavitem id="user" icon="/images/icons/user.svg" user>
            <UserPanel />
          </UiNavitem>
        </div>
      </div>
    </nav>
  </div>
</template>

<script type="ts" setup>
import { UiNavitem } from "~/components/ui";
import StreakPanel from '~/components/home/streak-panel.vue'
import StarsPanel from '~/components/home/stars-panel.vue'
import UserPanel from '~/components/home/user-panel.vue'

const authStore = useAuthStore();
const { goBack, hideBackIcon, isInHome, hideButtons } = useNavbar()

const { info } = useDevice()

const shouldHaveExtraPadding = computed(() => {
  return info.value.isApp && info.value.isIphone && info.value.isPortrait
})
</script>