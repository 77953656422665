import { dayjs } from "~/libs/dayjs";
import { useStorage } from '@vueuse/core'

export function useToken() {
  const config = useRuntimeConfig();
  const cookieToken = useCookie<string | null>("token", {
    maxAge: 31536000,
    default: () => ''
  });

  const storageToken = useStorage("token", "");

  const token = computed({
    get: () => {
      if (config.public.isInAppMode) {
        return storageToken.value;
      }

      return cookieToken.value;
    },
    set: (value: string | null) => {
      if (config.public.isInAppMode) {
        storageToken.value = value;
      } else {
        cookieToken.value = value;
      }
    },
  });

  return token;
}
