<template>
  <div>
    <div class="text-accent relative" :class="{
      'pt-[35px]': isApp
    }">
      <slot />
    </div>
  </div>
  <Toaster />
</template>

<script type="ts" setup>
import { Toaster } from '~/components/toast'

useLoggedInChecker()

const isApp = useIsApp();
</script>
