<template>
  <ul class="mt-4 grid gap-2 md:text-[18px] md:font-medium">
    <li v-for="b in benefits" :key="b">✅ {{ b }}</li>
  </ul>
</template>

<script setup lang="ts">
import { usePlans } from '~/domains/payment/composables/usePlans';

const { benefits } = usePlans();

</script>