<template>
  <div class="landing-container">
    <div class="md:flex hidden flex-col items-center mb-20 max-w-[800px] mx-auto text-center">
      <h2 class="text-[28px] md:text-[42px] font-serif">Estude de forma inteligente</h2>
      <p class="text-gray-700 text-lg md:text-[22px] font-medium">
        Aprenda de maneira ativa resolvendo questões e saiba sua nota usando o TRI em tempo real.
      </p>
    </div>
    <div class="grid md:grid-cols-2 gap-20 gap-x-32">
      <Feature v-for="(feature, index) in features" :key="feature.title" v-bind="feature" :class="{
        'md:mt-[200px]': index % 2 === 1
      }" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Feature from '~/components/landing/feature.vue'

const features = [
  {
    title: 'Estudo guiado',
    description: 'Nossa IA escolhe questões sobre os assuntos que mais caem no ENEM e que são mais relevantes pra você.',
    img: '/images/landing/features/1.png',
    video: '/videos/feature_1.mp4',
    color: 'bg-[#D3EEFF]',
  },
  {
    title: 'Resoluções detalhadas',
    description: 'Resoluções detalhadas que te mostram como resolver questões similares no futuro.',
    img: '/images/landing/features/2.png',
    video: '/videos/feature_2_web.mp4',
    color: 'bg-[#FFE1D6]',
  }, {
    title: 'Nota em tempo real',
    description: 'Veja sua nota em tempo real calculada usando os mesmos critérios do ENEM (TRI).',
    img: '/images/landing/features/3.png',
    video: '/videos/feature_3_web.mp4',
    color: 'bg-[#DCFFCE]',
  }, {
    title: 'Nota por competência',
    description: 'Veja sua nota por competência e faça questões sobre os assuntos que você precisa melhorar.',
    img: '/images/landing/features/4.png',
    video: '/videos/feature_4_web.mp4',
    color: 'bg-[#DFFFFA]',
  },
]
</script>
