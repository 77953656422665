<template>
  <div>
    <div class="w-screen relative" v-if="!loading">
      <UiNavbar />
      <div class="container text-accent py-10">
        <NuxtPage />
      </div>
    </div>
    <div class="flex justify-center items-center h-screen" v-else>
      <UiLoading />
    </div>
    <Toaster />
    <PaymentModal />
  </div>
</template>

<script type="ts" setup>
import PaymentModal from '~/components/payment/payment-modal.vue';
import { Toaster } from '~/components/toast'
import { UiNavbar, UiLoading } from "~/components/ui";

const { loading, checkAuth } = useAuthHandler()

onMounted(async () => {
  await checkAuth()
})

useHead({
  title: "respostaCerta - O jeito rápido, eficaz e gratuito de aumentar sua nota no ENEM",
});

</script>

<style lang="sass" scoped>
body
  scrollbar-gutter: stable both-edges
</style>
