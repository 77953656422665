<template>
  <div class="mb-20 md:my-[200px] landing-container">
    <div class=" flex-col items-center max-w-[800px] mx-auto text-center mb-1">
      <h2 class="text-[28px] md:text-[42px] font-serif">Simule o dia da prova com TRI oficial</h2>
    </div>

    <div class="flex flex-col items-center mb-10">
      <p v-for="(perk, i) in perks" :key="perk" v-motion :initial="{
        opacity: 0,
        y: 10
      }" :enter="{
        opacity: 1,
        y: 0,
        transition: {
          type: 'spring'
        }
      }" :delay="(i * 100) + 300"
        class="opacity-0 max-w-[700px] inline-block text-[16px] md:text-[20px] text-center mt-[6px]">
        <span v-html="perk"></span>
      </p>
    </div>

    <div v-if="smAndLarger" class="border-2 border-accent rounded-[20px] overflow-hidden max-w-[960px] mx-auto w-full h-full relative">
      <img src="/images/landing/test/wide.jpg" class="w-full h-full object-cover" />
      <video playsinline src="/videos/test_wide.mp4" autoplay loop muted
        class="w-full h-full object-cover absolute top-0 left-0 z-10" />
    </div>
    <div v-else class="border-2 border-accent rounded-[20px] overflow-hidden max-w-[850px] mx-auto w-full h-full relative">
      <img src="/images/landing/test/1.jpg" class="w-full h-full object-cover" />
      <video playsinline src="/videos/test_3.mp4" autoplay loop muted
        class="w-full h-full object-cover absolute top-0 left-0 z-10" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const perks = [
  '🕹️ <b>Simulados exclusivos</b>',
  '📚 Mais de <b>6 mil questões</b> de todas edições do ENEM',
  '🎯 Nota usando <b>TRI oficial</b> baseado nos microdados da prova',
  '📝 Relatório completo com <b>pontos fortes e fracos</b>',
  '⏰ Controle de <b>tempo e questões</b> respondidas'
]

import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints(breakpointsTailwind)

const smAndLarger = breakpoints.greaterOrEqual('sm')
</script>