import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const randomBetween = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const chooseFromArray = (array: any[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const calculateTimeFromSeconds = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60)

  return {
    minutes,
    seconds,
  };
}