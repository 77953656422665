<template>
  <img v-motion
    :initial="{
      y: 2,
      x: 2
    }"
    :enter="{
      y: -2,
      x: -2,
      transition: {
        duration: 2000,
        repeat: Infinity,
        repeatType: 'mirror',
      },
    }" />
</template>